.aboutUsFounderMainContainer {
  background-color: #fff;
  margin-top: 1.866666667vw;
  margin-bottom: 10.666666667vw;
}

.aboutUsFounderContainer {
  max-width: 350.4vw;
  padding: 0 9.06666667vw 8.5333333vw 9.06666667vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 6.4vw;
  background-color: #fbfbfb;
  box-shadow: 0 1.06666667vw 5.3333333vw rgba(58, 117, 104, 0.1);
}

.aboutUsFounderSectionTitle {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.666666667vw;
  color: #0f3c50;
  margin-bottom: 6.4vw;
  max-width: 350.4vw;
  padding-left: 8.5333333vw;
  padding-right: 8.5333333vw;
  margin-left: auto;
  margin-right: auto;
}

.AboutUsFounderMedia {
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 16px;
  overflow: hidden;
}

.aboutUsFounderImage {
  width: 100%;
  border-radius: 16px;
}

.aboutUsFounderTextTitle {
  font-size: 6.4vw;
  font-weight: 600;
  line-height: 8.5333333vw;
  color: #0f3c50;
  /* margin-bottom: 32px; */
  align-items: center;
  display: inline-block;
  border-radius: 8px;
  margin-left: 2.6666667vw;
}

.aboutUsFounderTextSubtitle {
  font-size: 5.3333333vw;
  font-weight: 600;
  line-height: 8vw;
  color: #0f3c50;
  margin-bottom: 2.1333333vw;
  margin-top: 10.6666667vw;
}

.aboutUsFounderTextDescription {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 5.3333333vw;
  color: #0f3c50;
  margin-top: 3.2vw;
  margin-right: 14px;
}

.aboutUsFounderWhatsApp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.13333333vw 4.266666667vw;
  gap: 2.666666667vw;
  border: 0;
  background-color: #ebeb78;
  border-radius: 40vw;
  font-weight: 600;
  font-size: 3.73333333vw;
  line-height: 5.6vw;
  color: #0f3c50;
  min-height: 9.86666666667vw;
}

.aboutUsFounderEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.13333333vw 4.266666667vw;
  gap: 2.66666667vw;
  border: 1px solid #0f3c50;
  border-radius: 40vw;
  font-weight: 600;
  font-size: 3.73333333vw;
  line-height: 5.6vw;
  color: #0f3c50;
  min-height: 9.86666666667vw;
  background-color: #fff;
}

.aboutUsFounderSocialButtons {
  display: flex;
  flex-direction: column;
  gap: 4.8vw;
  margin-top: 5.3333333vw;
}

.pc {
  display: none !important;
}

.sp {
  display: inline-block !important;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .aboutUsFounderImage {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    object-position: top;
    position: absolute;
    top: 0;
    left: 0;
  }

  .AboutUsFounderMedia {
    margin-top: 13.333333333vw;
    position: relative;
    padding-top: 66%;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  /*.AboutUsFounderMedia {*/
  /*  display: flex;*/
  /*  justify-content: center;*/
  /*  align-items: center;*/
  /*  width: 100%;*/
  /*  margin: 0;*/
  /*  margin-top: 40px;*/
  /*}*/

  /*.aboutUsFounderImage {*/
  /*  width: 400px;*/
  /*  max-height: 500px;*/
  /*  justify-content: center;*/
  /*  align-self: center;*/
  /*}*/

  /*.aboutUsFounderSocialButtons {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  margin-top: 20px;*/
  /*}*/
  .aboutUsFounderMainContainer {
    margin-top: 5.9375vw;
    margin-bottom: 5.9375vw;
  }

  .aboutUsFounderContainer {
    display: flex;
    max-width: 82.125vw;
    flex-direction: row;
    /* gap: 42px; */
    align-items: unset;
    justify-content: center;
    margin-top: 3.75vw;
    padding: 3.75vw 1.75vw;
    border-radius: 1vw;
    box-shadow: 0px 4px 20px rgba(58, 117, 104, 0.1);
  }

  .aboutUsFounderSectionTitle {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 2.5vw;
    color: #0f3c50;
    margin-bottom: 2.5vw;
    padding-left: 0;
    padding-right: 0;
    max-width: 85.625vw;
  }

  .AboutUsFounderMedia {
    display: block;
    max-width: 25vw;
    max-height: 31.25vw;
    margin: 0;
  }

  .aboutUsFounderImage {
    width: 100%;
  }

  .aboutUsFounderText {
    width: 65%;
  }

  .aboutUsFounderTextTitle {
    font-size: 2vw;
    font-weight: 600;
    line-height: 2.5vw;
    color: #0f3c50;
    margin: 0;
    display: inline-block;
    /* margin-bottom: 32px; */
  }

  .aboutUsFounderSocialButtons {
    display: flex;
    flex-direction: row;
    margin-top: 1.25vw;
    flex-wrap: wrap;
    gap: 1.125vw;
  }

  .aboutUsFounderWhatsApp {
    min-height: 2.3125vw;
    font-size: 0.875vw;
    gap: 0.625vw;
    line-height: 1.3125vw;
    padding: 0.5vw 1vw;
  }

  .aboutUsFounderEmail {
    min-height: 2.3125vw;
    font-size: 0.875vw;
    gap: 0.625vw;
    line-height: 1.3125vw;
    padding: 0.5vw 1vw;
  }

  .right {
    margin-right: 4.125vw;
  }

  .left {
    margin-left: 4.125vw;
  }

  .aboutUsFounderTextSubtitle {
    font-size: 1.25vw;
    line-height: 1.875vw;
    margin-top: 0;
    margin-bottom: 0.9375vw;
  }

  .aboutUsFounderTextDescription {
    font-size: 1vw;
    line-height: 1.25vw;
    margin-top: 0.9375vw;
    margin-right: 0;
  }

  .sp {
    display: none !important;
  }

  .pc {
    display: inline-block !important;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .aboutUsFounderMainContainer {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .aboutUsFounderContainer {
    display: flex;
    max-width: 1314px;
    flex-direction: row;
    /* gap: 42px; */
    align-items: unset;
    justify-content: center;
    margin-top: 60px;
    padding: 60px 28px;
    border-radius: 16px;
    box-shadow: 0px 4px 20px rgba(58, 117, 104, 0.1);
  }

  .aboutUsFounderSectionTitle {
    font-size: 54px;
    font-weight: 700;
    line-height: 40px;
    color: #0f3c50;
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    max-width: 1370px;
  }

  .AboutUsFounderMedia {
    display: block;
    max-width: 400px;
    max-height: 500px;
    margin: 0;
  }

  .aboutUsFounderImage {
    width: 100%;
  }

  .aboutUsFounderText {
    width: 65%;
  }

  .aboutUsFounderTextTitle {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: #0f3c50;
    margin: 0;
    display: inline-block;
    /* margin-bottom: 32px; */
  }

  .aboutUsFounderSocialButtons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 18px;
  }

  .aboutUsFounderWhatsApp {
    min-height: 37px;
    font-size: 14px;
    gap: 10px;
    line-height: 21px;
    padding: 8px 16px;
  }

  .aboutUsFounderEmail {
    min-height: 37px;
    font-size: 14px;
    gap: 10px;
    line-height: 21px;
    padding: 8px 16px;
  }

  .right {
    margin-right: 55px;
  }

  .left {
    margin-left: 71px;
  }

  .aboutUsFounderTextSubtitle {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .aboutUsFounderTextDescription {
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 0;
  }

  .sp {
    display: none !important;
  }

  .pc {
    display: inline-block !important;
  }
}
