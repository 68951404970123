.header {
  position: fixed;
  top: 0;
  padding: 10px 40px;
  height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  box-sizing: border-box;
  transition-duration: 0.25s;
}

.header__logo {
  cursor: pointer;
  width: 134px;
  height: 48px;
  padding-bottom: 10px;
}

.header__leftPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 16px;
}

.header__rightPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.header__quiSommesNous {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  width: fit-content;
  min-width: fit-content;
}

.header__quiSommesNous:hover {
  text-decoration: underline;
}

.header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid white;
  border-radius: 16px;
  height: 21px;
  background: transparent;
  color: white;
  padding: 16px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  font-family: poppins;
}

.header__language {
    font-size: 14px;
}

.header__button:hover {
  text-decoration: underline;
}

.header__dropdownContainer {
  display: none;
}

.header__hamburgerContainer {
  display: none;
}

@media screen and (max-width: 767px) {
  .header {
  }

  .header__hamburgerContainer {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
    width: fit-content;
    height: fit-content;
  }

  .header__dropdownContainer {
    display: inline-block;
    position: absolute;
    top: 79px;
    height: fit-content;
    max-height: 300px;
    width: 100%;
    margin: -1px -40px;
  }

  .header__closeBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #0f3c50;
    height: 100vh;
    z-index: -1;
    opacity: 0.9;
  }

  .header__dropdownContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin: 0;
    gap: 32px;
    height: 90vh;
    background: #0f3c50;
    opacity: 0; /* here */
    position: absolute;
    z-index: 1;
    transition: all 0.15s ease-in; /* here */
    transform: translateY(-10px); /* here */
    box-sizing: border-box;
  }

  .header__dropdownContent a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    font-weight: 600;
  }

  .header__rightPart {
    display: none;
  }

  .header__quiSommesNous {
    display: none;
  }

  .header__language {
    font-size: 14px;
}
}

/*@media screen and (min-width: 2160px) {*/
/*    .header {*/
/*        height: fit-content;*/
/*    }*/

/*    .header__logo {*/
/*        width: calc(134px + 3vw);*/
/*        height: calc(48px + 3vw);*/
/*    }*/

/*    .header__leftPart {*/
/*        gap: 1vw;*/
/*    }*/

/*    .header__quiSommesNous {*/
/*        font-size: 0.8vw;*/
/*    }*/

/*    .header__rightPart {*/
/*        gap: 1vw*/
/*    }*/

/*    .header__button {*/
/*        border-radius: 1vw;*/
/*        padding: 1vw 1vw;*/
/*        font-size: 0.8vw;*/
/*    }*/

/*    .header__language {*/
/*        font-size: 0.8vw;*/
/*    }*/

/*    .header__languageIcon {*/
/*        padding-right: 0.3vw;*/
/*        min-width: 1vw;*/
/*        min-height: 1vw;*/
/*    }*/
/*}*/
