.aboutUsHero {
    min-height: 172vw;
    /* margin-top: -60px; */
    padding-top: 0;
    background-image: url("./../../../Assets/home-hero-cover_sp.jpg");
    /* background-position: 50% 0%; */
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.aboutUsHeroContainer {
    width: 100%;
    max-width: 346.6666666667vw;
    padding-left: 8.53333333333vw;
    padding-right: 8.53333333333vw;
    margin-left: auto;
    margin-right: auto;
}

.aboutUsHeroContent {
    margin-top: 5.33333333333vw;
}

.aboutUsHeroHeading {
    width: auto;
    color: #0f3c50;
    font-size: 8.53333333333vw;
    font-weight: 700;
    line-height: 13.3333333333vw;
    margin: 0;
}

.aboutUsHeroHeadingText {
    background-color: #ebeb78;
}

.aboutUsHeroHeadingText.sp {
    display: inline;
}

.aboutUsHeroHeadingText.pc {
    display: none;
}

.aboutUsHeroTitle {
    font-size: 6.4vw;
    font-weight: 600;
    line-height: 6.9333333333vw;
    color: #fff;
    margin-bottom: 5.33333333333vw;
    margin-top: 8vw;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
    /*.aboutUsHero {*/
    /*    height: 800px;*/
    /*    background-image: url("./../../../Assets/Images/about-us-hero-cover.png");*/
    /*}*/

    /*.aboutUsHeroTitle {*/
    /*    width: 70%;*/
    /*}*/
    .aboutUsHero {
        min-height: 50vw;
        /* margin-top: -78px; */
        background-image: url("./../../../Assets/Images/about-us-hero-cover.png");
    }

    .aboutUsHeroContent {
        margin-top: 7vw;
    }

    .aboutUsHeroHeading {
        width: auto;
        color: #0f3c50;
        font-size: 4vw;
        font-weight: 700;
        line-height: 4vw;
        margin: 0;
    }

    .aboutUsHeroHeadingText {
        background-color: #ebeb78;
    }

    .aboutUsHeroHeadingText.sp {
        display: none;
    }

    .aboutUsHeroHeadingText.pc {
        display: inline;
    }

    .aboutUsHeroTitle {
        font-size: 2vw;
        font-weight: 600;
        line-height: 2.5vw;
        color: #fff;
        margin-bottom: 3.125vw;
        width: 55%;
        /* text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
        margin-top: 2.5vw;
        max-width: 44.6875vw;
    }
}

/* Desktops */
@media screen and (min-width: 1600px) {
    .aboutUsHero {
        min-height: 800px;
        /* margin-top: -78px; */
        background-image: url("./../../../Assets/Images/about-us-hero-cover.png");
    }

    .aboutUsHeroContent {
        margin-top: 112px;
    }

    .aboutUsHeroHeading {
        width: auto;
        color: #0f3c50;
        font-size: 64px;
        font-weight: 700;
        line-height: 64px;
        margin: 0;
    }

    .aboutUsHeroHeadingText {
        background-color: #ebeb78;
    }

    .aboutUsHeroHeadingText.sp {
        display: none;
    }

    .aboutUsHeroHeadingText.pc {
        display: inline;
    }

    .aboutUsHeroTitle {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        color: #fff;
        margin-bottom: 50px;
        width: 55%;
        /* text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
        margin-top: 40px;
        max-width: 715px;
    }
}
