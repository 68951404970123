.aboutUsInNumber {
    min-height: auto;
    padding-top: 42.6666667vw;
    padding-bottom: 74.6666666667vw;
    background-image: url("./../../../Assets/Images/about-us-numbers-sp.png");
    background-position: center;
    background-size: 100% 100%;
    object-fit: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

/* .aboutUsInNumberContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
} */

.aboutUsInNumberContainer {
    max-width: 350.4vw;
    padding: 0 8.533333333vw 8.533333333vw 8.533333333vw;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 14.66666667vw;
}

.aboutUsInNumberHeading {
    width: auto;
    color: #0f3c50;
    font-size: 8.533333333vw;
    font-weight: 700;
    line-height: 10.66666667vw;
    text-align: center;
    margin-bottom: 10.66666667vw;
}

.aboutUsInNumberHeadingText.sp {
    display: inline;
}

.aboutUsInNumberHeadingText.pc {
    display: none;
}

.aboutUsInNumberCountsContainer {
    display: flex;
    flex-direction: column;
    padding: 10.66666667vw;
    width: auto;
    margin: 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 16px;
}

.aboutUsInNumberCountsHeading {
    width: auto;
    color: #0f3c50;
    font-size: 8.533333333vw;
    font-weight: 600;
    line-height: 16vw;
    margin: 0;
    text-align: center;
}

.aboutUsInNumberCountsParagraph {
    width: auto;
    color: #0f3c50;
    font-size: 4.266666667vw;
    font-weight: 400;
    line-height: 6.4vw;
    margin: 0;
    text-align: center;
}

.aboutUsInNumberCountsChildContainer {
    padding: 6.4vw 10.66666667vw;
    align-self: center;
}

hr {
    align-self: center;
    width: 40%;
    border-color: #0F3C50;
    opacity: 0.4;
}

.aboutUsInNumberStatsPC {
    display: none;
}

.aboutUsInNumberStatsSP {
    display: block;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
    .aboutUsInNumberHeading {
        text-align: left;
    }

    .aboutUsInNumberContainer {
        margin-left: auto;
        margin-right: auto;
    }

    .aboutUsInNumberCountsContainer {
        padding: 2.666667vw;
    }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
    /*.aboutUsInNumber {*/
    /*    height: 800px;*/
    /*    padding-top: 160px;*/
    /*    background-position: center;*/
    /*    background-size: cover;*/
    /*    background-image: url("./../../../Assets/Images/about-us-numbers.png");*/
    /*}*/
    .aboutUsInNumberHeading {
        font-size: 2.5vw;
        line-height: 2.5vw;
        margin-bottom: 2.625vw;
        text-align: center;
    }

    .aboutUsInNumber {
        min-height: 34.6875vw;
        /* margin-top: -78px; */
        padding-top: 0;
        padding-bottom: 0;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("./../../../Assets/Images/about-us-numbers.png");
    }

    .aboutUsHeroContent {
        margin-top: 4.875vw;
    }

    .aboutUsInNumberHeadingText.sp {
        display: none;
    }

    .aboutUsInNumberHeadingText.pc {
        display: inline;
    }

    .aboutUsInNumberCountsContainer {
        display: block;
        padding: 1.25vw 2.5vw;
    }

    .aboutUsInNumberCountGrid {
        display: flex;
        flex-direction: row;
        width: 25%;
    }

    .aboutUsInNumberCountsChildContainer {
        padding: 1.25vw 1.25vw;
        align-self: center;
        width: 100%;
    }

    .aboutUsInNumberStatsPC {
        display: flex;
        flex-direction: row;
    }

    .aboutUsInNumberStatsSP {
        display: none;
    }

    hr {
        align-self: center;
        height: 70%;
        width: 0;
        background-color: #000;
        color: #000;
        border-color: #0F3C50;
    }

    .aboutUsNumberGrid {
        width: 25%;
    }

    .aboutUsInNumberCountsHeading {
        font-size: 2.5vw;
        line-height: 3.75vw;
    }

    .aboutUsInNumberCountsParagraph {
        min-width: 15.1875vw;
        font-size: 1vw;
        line-height: 1.5vw;
    }
}

/* Desktops */
@media screen and (min-width: 1600px) {
    .aboutUsInNumber {
        min-height: 555px;
        /* margin-top: -78px; */
        padding-top: 0;
        padding-bottom: 0;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("./../../../Assets/Images/about-us-numbers.png");
    }

    .aboutUsInNumberHeading {
        font-size: 40px;
        margin-bottom: 42px;
        line-height: 40px;
    }

    .aboutUsHeroContent {
        margin-top: 78px;
    }

    .aboutUsInNumberHeadingText.sp {
        display: none;
    }

    .aboutUsInNumberHeadingText.pc {
        display: inline;
    }

    .aboutUsInNumberCountsContainer {
        display: block;
        padding: 20px 40px;
    }

    .aboutUsInNumberCountGrid {
        display: flex;
        flex-direction: row;
        width: 25%;
    }

    .aboutUsInNumberCountsChildContainer {
        padding: 20px 20px;
        align-self: center;
        width: 100%;
    }

    .aboutUsInNumberStatsPC {
        display: flex;
        flex-direction: row;
    }

    .aboutUsInNumberStatsSP {
        display: none;
    }

    hr {
        align-self: center;
        height: 70%; 
        width: 0%;
        background-color: #000; 
        color: #000;
        border-color: #0F3C50;
    }

    .aboutUsNumberGrid {
        width: 25%;
    }

    .aboutUsInNumberCountsHeading {
        font-size: 40px;
        line-height: 60px;
    }

    .aboutUsInNumberCountsParagraph {
        min-width: 243px;
        font-size: 16px;
        line-height: 24px;
    }
}
