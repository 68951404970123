.aboutUsMainContainer {
  background-color: #fff;
  margin-top: 12vw;
  margin-bottom: 10.6666666667vw;
}

.aboutUsContainer {
  padding-left: 7.46666667vw;
  padding-right: 7.46666667vw;
  margin-left: auto;
  margin-right: auto;
}

.aboutUsMedia {
  display: block;
  width: 100%;
  margin: 0;
}

.aboutUsImage {
  width: 100%;
}

.aboutUsTextTitle {
  font-size: 8vw;
  font-weight: 700;
  line-height: 10.6666666667vw;
  color: #0f3c50;
  margin-bottom: 8.5333333333vw;
  margin-top: 7.4666666667vw;
}

.aboutUsTextDescription {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #0f3c50;
  margin-top: 6.4vw;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .aboutUsMainContainer {
    margin-top: 8.75vw;
    margin-bottom: 6vw;
  }

  .aboutUsContainer {
    max-width: 82.125vw;
    display: flex;
    flex-direction: row;
    gap: 2.625vw;
    align-items: center;
  }

  .aboutUsMedia {
    display: block;
    width: 39.875vw;
    margin: 0;
  }

  .aboutUsImage {
    width: 100%;
  }

  .aboutUsText {
    width: 36.2vw;
  }

  .aboutUsTextTitle {
    margin-top: 0;
  }

  .aboutUsTextTitle {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 3.75vw;
    color: #0f3c50;
    margin-bottom: 2vw;
  }

  .aboutUsTextDescription {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    color: #0f3c50;
    margin-top: 1.5vw;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .aboutUsMainContainer {
    margin-top: 140px;
    margin-bottom: 96px;
  }

  .aboutUsContainer {
    max-width: 1314px;
    display: flex;
    flex-direction: row;
    gap: 42px;
    align-items: center;
  }

  .aboutUsMedia {
    display: block;
    width: 55%;
    margin: 0;
  }

  .aboutUsImage {
    width: 100%;
  }

  .aboutUsText {
    width: 606px;
  }

  .aboutUsTextTitle {
    margin-top: 0;
  }

  .aboutUsTextTitle {
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    color: #0f3c50;
    margin-bottom: 32px;
  }

  .aboutUsTextDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #0f3c50;
    margin-top: 24px;
  }
}
