.container {
  padding-left: 5.333333333vw;
  padding-right: 5.333333333vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20.8vw;
  padding-top: 2.666666667vw;
  padding-bottom: 11.46666667vw;
}

#privacy h1 {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.13333333vw;
  color: #0f3c50;
  margin-top: 8.533333333vw;
  margin-bottom: 8.533333333vw;
}

#privacy h2 {
  font-size: 8vw;
  font-weight: 700;
  line-height: 8.533333333vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 4.266666667vw;
}

#privacy p {
  font-size: 3.9vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #0f3c50;
  margin-top: 4.266666667vw;
  margin-bottom: 4.266666667vw;
}

/* Smartphones (Portrait phones) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .container {
    max-width: 92.0625vw;
    min-height: 92vw;
    padding-top: 2.75vw;
    padding-bottom: 3.3125vw;
    margin-top: 78px;
  }

  #privacy h1 {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 3.75vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 2.0625vw;
  }

  #privacy h2 {
    font-size: 2vw;
    font-weight: 700;
    line-height: 2.375vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }

  #privacy p {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    color: #0f3c50;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1473px;
    min-height: 1000px;
    padding-top: 44px;
    padding-bottom: 53px;
    margin-top: 78px;
  }

  #privacy h1 {
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 33px;
  }

  #privacy h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }

  #privacy p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #0f3c50;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
