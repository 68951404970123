.our_team {
  background-color: #c3dcaf;
}

.our_team_container {
  padding: 12.8vw 0px;
  margin-left: auto;
  margin-right: auto;
}

.our_team_map {
  width: 100%;
  margin-top: 12.8vw;
  display: flex;
  justify-content: center;
}

.our_team_map_img {
  width: 132%;
  object-fit: contain;
}

.our_team_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2vw;
  object-position: 26% 50%;
}

.our_team_section_title {
  margin-top: 0;
}

.our_team_title {
  white-space: pre-line;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

/* .MuiImageListItemBar-title {
    font-size: 32px;
} */

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .our_team_section_title {
    font-size: 8.533333333vw;
    font-weight: 700;
    color: #0f3c50;
    padding-left: 7.466666667vw;
    padding-right: 7.466666667vw;
    line-height: 10.66666667vw;
    margin-bottom: 9.066666667vw;
  }

  .our_team_section_desc {
    font-size: 4.266666667vw;
    font-weight: 400;
    color: #0f3c50;
    padding-left: 7.466666667vw;
    padding-right: 7.466666667vw;
    margin-bottom: 10.66666667vw;
  }

  .our_team_location_text {
    padding-left: 4.8vw;
  }

  .our_team_title {
    margin-top: 6vw;
    font-size: 5.333333333vw;
    font-weight: 700;
    padding-left: 6.4vw;
    padding-right: 6.4vw;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* -webkit-line-clamp: 1;  */
  }

  .our_team_img {
    width: 100%;
    height: 54.66666667vw;
    object-fit: cover;
    border-radius: 8.533333333vw;
    object-position: 27% 50%;
  }

  .our_team_text {
    font-size: 3.2vw;
    font-weight: 500;
    color: white;
    padding-left: 4vw;
    width: 88%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our_team_marker {
    width: 6.4vw;
    display: flex;
    align-items: center;
  }

  .our_team_grid {
    display: none;
  }

  .our_team_grid_mobile {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .our_team {
    filter: drop-shadow(0px 0.25vw 0.25vw rgba(0, 0, 0, 0.25));
  }

  .our_team_header {
    margin-bottom: 3.125vw;
  }
  .our_team_section_title {
    font-size: 3.375vw;
    font-weight: 700;
    color: #0f3c50;
    padding: 0;
    margin: 0;
  }

  .our_team_container {
    padding: 6vw 1.25vw 2.8125vw 1.25vw;
    max-width: 81.9375vw;
    margin-left: auto;
    margin-right: auto;
  }

  .our_team_section_desc {
    font-size: 1.5vw;
    font-weight: 400;
    color: #0f3c50;
    width: 60%;
    margin-bottom: 3.75vw;
  }

  .our_team_title {
    margin-top: 3.375vw;
    font-size: 2vw;
    font-weight: 700;
    line-height: 2.5vw;
    padding-left: 3.5vw;
    padding-right: 2vw;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our_team_location_text {
    padding-left: 3.125vw;
    margin-bottom: 2.625vw !important;
  }

  .our_team_text {
    font-size: 0.875vw;
    font-weight: 500;
    color: white;
    align-self: center;
    padding-left: 1.5vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our_team_marker {
    font-size: 2.125vw;
    object-fit: contain;
    display: flex;
    align-items: center;
    width: 1.5vw;
    height: 2.125vw;
  }

  .our_team_countries {
    align-items: center;
    justify-content: center;
    border: 0.125vw solid black;
    align-content: center;
  }

  .our_team_france {
    width: 100%;
    height: 50%;
    background-color: aqua;
    display: flex;
    align-items: center;
    border-radius: 2vw;
    overflow: hidden;
  }

  .our_team_france_img {
    width: 100%;
    object-fit: contain;
  }

  .our_team_tunisie {
    width: 100%;
    height: 50%;
    background-color: aqua;
    border-radius: 2vw;
    overflow: hidden;
  }

  .our_team_cambodia {
    width: 100%;
    height: 50%;
    background-color: aqua;
    border-radius: 2vw;
    overflow: hidden;
  }

  .our_team_other {
    width: 100%;
    height: 100%;
    border: 2px solid black;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .our_team_grid {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .our_team_grid_mobile {
    display: none;
  }

  .our_team_map {
    margin-top: 3.375vw;
  }

  .our_team_map_img {
    width: 73%;
    object-fit: contain;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .our_team_img {
    object-position: 14% 50%;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .our_team {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .our_team_header {
    margin-bottom: 50px;
  }
  .our_team_section_title {
    font-size: 54px;
    font-weight: 700;
    color: #0f3c50;
    padding: 0;
    margin: 0;
  }

  .our_team_img {
    object-position: 14% 50%;
  }

  .our_team_container {
    padding: 96px 20px 45px 20px;
    max-width: 1311px;
    margin-left: auto;
    margin-right: auto;
  }

  .our_team_section_desc {
    font-size: 24px;
    font-weight: 400;
    color: #0f3c50;
    width: 60%;
    margin-bottom: 60px;
  }

  .our_team_title {
    margin-top: 54px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    padding-left: 56px;
    padding-right: 32px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our_team_location_text {
    padding-left: 50px;
    margin-bottom: 42px !important;
  }

  .our_team_text {
    font-size: 14px;
    font-weight: 500;
    color: white;
    align-self: center;
    padding-left: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our_team_marker {
    font-size: 34px;
    object-fit: contain;
    display: flex;
    align-items: center;
    width: 24px;
    height: 34px;
  }

  .our_team_countries {
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    align-content: center;
  }

  .our_team_france {
    width: 100%;
    height: 50%;
    background-color: aqua;
    display: flex;
    align-items: center;
    border-radius: 32px;
    overflow: hidden;
  }

  .our_team_france_img {
    width: 100%;
    object-fit: contain;
  }

  .our_team_tunisie {
    width: 100%;
    height: 50%;
    background-color: aqua;
    border-radius: 32px;
    overflow: hidden;
  }

  .our_team_cambodia {
    width: 100%;
    height: 50%;
    background-color: aqua;
    border-radius: 32px;
    overflow: hidden;
  }

  .our_team_other {
    width: 100%;
    height: 100%;
    border: 2px solid black;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .our_team_grid {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .our_team_grid_mobile {
    display: none;
  }

  .our_team_map {
    margin-top: 54px;
  }

  .our_team_map_img {
    width: 73%;
    object-fit: contain;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }
}
