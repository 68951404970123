.ourClients {
  background-color: #fff;
  margin-top: 10.66666667vw;
  margin-bottom: 10.66666667vw;
}

.container {
  padding-left: 5.333333333vw;
  padding-right: 5.333333333vw;
  margin-left: auto;
  margin-right: auto;
}

.slideContainer {
  margin-left: auto;
  margin-right: auto;
}

.ourClients__heading {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #0f3c50;
  margin: 0;
}

.ourClients__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10.66666667vw;
}

.clientLogo {
  width: 100%;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .ourClients {
    background-color: #fff;
    margin-top: 8.75vw;
    margin-bottom: 8.75vw;
  }

  .container {
    max-width: 82.125vw;
  }

  .slideContainer {
    max-width: 82.125vw;
    padding-left: -1.25vw;
    padding-right: -1.25vw;
    margin-left: auto;
    margin-right: auto;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .ourClients__heading {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 2.5vw;
    color: #0f3c50;
    margin-top: 0;
    /* text-shadow: 0 0.25vw 0.25vw rgba(0, 0, 0, 0.25); */
  }

  .ourClients__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.75vw;
  }

  .clientLogoContainer {
    height: 3.75vw;
  }

  .clientLogo {
    height: 3.75vw;
    object-fit: contain;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1314px;
  }

  .ourClients {
    background-color: #fff;
    margin-top: 140px;
    margin-bottom: 140px;
  }

  .slideContainer {
    max-width: 1314px;
    padding-left: -20px;
    padding-right: -20px;
    margin-left: auto;
    margin-right: auto;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .ourClients__heading {
    font-size: 54px;
    font-weight: 700;
    line-height: 40px;
    color: #0f3c50;
    margin-top: 0;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }

  .ourClients__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
  }

  .clientLogoContainer {
    height: 60px;
  }

  .clientLogo {
    height: 60px;
    object-fit: contain;
  }
}
