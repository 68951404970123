.homeHero {
  min-height: 172vw;
  /* margin-top: -16vw; */
  padding-top: 0px;
  background-image: url("./../../Assets/home-hero-cover_sp.jpg");
  /* background-position: 50% 0%; */
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  align-items: center;
}

.homeHero__container {
  width: 100%;
  max-width: 350.4vw;
  padding-left: 10.13333333vw;
  padding-right: 10.13333333vw;
  margin-left: auto;
  margin-right: auto;
}

.homeHero__content {
  margin-top: 14.93333333vw;
}

.homeHero__heading {
  width: auto;
  color: #0f3c50;
  font-size: 8.533333333333333vw;
  font-weight: 700;
  line-height: 13.33333333vw;
  margin: 0;
}

.homeHero__headingText {
  background-color: #ebeb78;
}

.homeHero__headingText.sp {
  display: inline;
}

.homeHero__headingText.pc {
  display: none;
}

.homeHero__title {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #fff;
  margin-bottom: 5.333333333vw;
  margin-top: 2.133333333vw;
}

.homeHero__button {
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .homeHero__button {
    width: 100%;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .homeHero {
    min-height: 50vw;
    /* margin-top: -4.875vw; */
    background-image: url("./../../Assets/home-hero-cover.jpg");
  }

  .homeHero__container {
    max-width: 82.125vw;
  }

  .homeHero__content {
    margin-top: 96px;
  }

  .homeHero__heading {
    width: auto;
    color: #0f3c50;
    font-size: 4vw;
    font-weight: 700;
    line-height: 4vw;
    margin: 0;
  }

  .homeHero__headingText {
    background-color: #ebeb78;
  }

  .homeHero__headingText.sp {
    display: none;
  }

  .homeHero__headingText.pc {
    display: inline;
  }

  .homeHero__title {
    font-size: 4vw;
    font-weight: 700;
    line-height: 5.3125vw;
    color: #fff;
    margin-bottom: 1.875vw;
    margin-top: 1.25vw;
    width: 70%;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .homeHero {
    min-height: 800px;
    /* margin-top: -78px; */
    background-image: url("./../../Assets/home-hero-cover.jpg");
  }

  .homeHero__container {
    max-width: 1314px;
  }

  .homeHero__content {
    margin-top: 96px;
  }

  .homeHero__heading {
    width: auto;
    color: #0f3c50;
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    margin: 0;
  }

  .homeHero__headingText {
    background-color: #ebeb78;
  }

  .homeHero__headingText.sp {
    display: none;
  }

  .homeHero__headingText.pc {
    display: inline;
  }

  .homeHero__title {
    font-size: 64px;
    font-weight: 700;
    line-height: 85px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 70%;
  }
}
