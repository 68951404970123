.mission {
  background-color: #fff;
  margin-top: 12vw;
  margin-bottom: 10.66666667vw;
}

.mission__container {
  max-width: 350.4vw;
  padding-left: 8vw;
  padding-right: 8vw;
  margin-left: auto;
  margin-right: auto;
}

.mission__media {
  display: none;
}

.mission__textTitle {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #0f3c50;
  margin-bottom: 8.533333333vw;
}

.mission__textDescription {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #0f3c50;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .mission {
    margin-top: 8.75vw;
    margin-bottom: 8.25vw;
  }

  .mission__container {
    display: flex;
    flex-direction: row;
    gap: 2.625vw;
    align-items: center;
  }

  .mission__media {
    display: block;
    width: 40.14375vw;
    margin: 0;
  }

  .mission__image {
    width: 100%;
  }

  .mission__text {
    width: 40.285vw;
  }

  .mission__textTitle {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 3.75vw;
    color: #0f3c50;
    margin-bottom: 2vw;
    margin-top: 0.875vw;
  }

  .mission__textDescription {
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 2.125vw;
    color: #0f3c50;
    width: 80%;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .mission {
    margin-top: 140px;
    margin-bottom: 132px;
  }

  .mission__container {
    max-width: 1314px;
    display: flex;
    flex-direction: row;
    gap: 42px;
    align-items: center;
  }

  .mission__media {
    display: block;
    width: 51%;
    margin: 0;
  }

  .mission__image {
    width: 100%;
  }

  .mission__text {
    width: 50%;
  }

  .mission__textTitle {
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    color: #0f3c50;
    margin-bottom: 32px;
    margin-top: 14px;
  }

  .mission__textDescription {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    color: #0f3c50;
    width: 80%;
  }
}
