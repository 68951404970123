/*!* Smartphones (Portrait phones, 600px and down) *!*/
/*@media screen and (max-width: 600px){*/
/*    .container {*/
/*        display: flex;*/
/*        flex:1;*/
/*        justify-content: center;*/
/*        color: white;*/
/*        background-color: red;*/
/*        padding: 20px;*/
/*        font-size: 35px;*/
/*        font-weight: 600;*/
/*    }*/
/*}*/

/*!* Tablets (Portrait tablets 600px and 1024px) *!*/
/*@media screen and (min-width: 600px) and (max-width: 1024px){*/
/*    .container {*/
/*        display: flex;*/
/*        flex:1;*/
/*        justify-content: center;*/
/*        color: white;*/
/*        background-color: blue;*/
/*        padding: 20px;*/
/*        font-size: 35px;*/
/*        font-weight: 600;*/
/*    }*/
/*}*/

/*!* Desktops (Desktop 1024px) *!*/
/*@media screen and (min-width: 1024px){*/
/*    .container {*/
/*        display: flex;*/
/*        flex:1;*/
/*        justify-content: center;*/
/*        color: white;*/
/*        background-color: green;*/
/*        padding: 20px;*/
/*        font-size: 35px;*/
/*        font-weight: 600;*/
/*    }*/
/*}*/
