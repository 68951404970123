.container {
  padding-left: 5.333333333vw;
  padding-right: 5.333333333vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20.8vw;
  padding-top: 2.666666667vw;
  padding-bottom: 11.46666667vw;
}

.component h2 {
  font-size: 4.8vw !important;
  font-weight: 700;
  line-height: 6.4vw !important;
  color: #0f3c50;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.component p {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #0f3c50;
  margin-top: 4.266666667vw;
  margin-bottom: 4.266666667vw;
}

.descriptionContainer {
  padding-left: 10.66666667vw !important;
}

.descriptionContainer table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 4.266666667vw;
}

.descriptionContainer th,
.descriptionContainer td {
  border: #0f3c50 solid 0.2666666667vw;
  font-size: 3.733333333vw;
  padding: 2.666666667vw 1.333333333vw;
  min-width: 53.33333333vw;
}

.descriptionContainer th {
  text-align: center;
}

.expandIcon {
  width: 4.8vw;
  height: 4.8vw;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .container {
    padding-left: 1.25vw;
    padding-right: 1.25vw;
    margin-top: 4.875vw;
    max-width: 82.125vw;
    min-height: 62.5vw;
    padding-top: 0.625vw;
    padding-bottom: 3.3125vw;
  }

  .component h2 {
    font-size: 2vw !important;
    font-weight: 700;
    line-height: 2.375vw !important;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }

  .component p {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    color: #0f3c50;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .descriptionContainer {
    padding-left: 2.625vw !important;
  }

  .expandIcon {
    width: 1.5vw;
    height: 1.5vw;
  }

  .descriptionContainer th,
  .descriptionContainer td {
    min-width: unset;
    border: #0f3c50 solid 0.0625vw;
    font-size: 0.875vw;
    padding: 0.625vw 0.3125vw;
    min-width: 12.5vw;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 78px;
    max-width: 1314px;
    min-height: 1000px;
    padding-top: 10px;
    padding-bottom: 53px;
  }

  .component h2 {
    font-size: 32px !important;
    font-weight: 700;
    line-height: 38px !important;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }

  .component p {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px !important;
    color: #0f3c50;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .descriptionContainer {
    padding-left: 42px !important;
  }

  .expandIcon {
    width: 24px;
    height: 24px;
  }

  .descriptionContainer th,
  .descriptionContainer td {
    min-width: unset;
    border: #0f3c50 solid 1px;
    font-size: 14px;
    padding: 10px 5px;
    min-width: 200px;
  }
}
