.logo {
  display: block;
  max-width: 14.66666667vw;
  height: auto;
}

.expanded-logo {
  justify-content: center;
  max-width: 14.66666667vw;
  max-height: 14.66666667vw;
  margin-left: auto;
  transition: opacity 0.5s ease-in-out;
}

.main-section {
  margin-bottom: 8vw;
}

.practices-container {
  padding-left: 5.333333333vw;
  padding-right: 5.333333333vw;
  margin-left: auto;
  margin-right: auto;
}

.practiceTextDescription {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 11vw;
  margin-top: 2.666666667vw;
  margin-right: 0.2666666667vw;
  white-space: pre-line;
}

.practiceTextTitle {
  font-size: 4.8vw;
  font-weight: 600;
  line-height: 7.2vw;
  margin: 0;
}

.practices-h1 {
  margin-top: 0;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .practices-container {
    flex: 1;
    justify-content: center;
    color: black;
    background-color: white;
    padding: 5.333333333vw;
    font-size: 9.333333333vw;
    font-weight: 600;
  }

  .grid-card {
    display: none;
  }

  .practices-h1 {
    font-size: 8.533333333vw;
    font-weight: 700;
    color: #0f3c50;
  }

  .p-tag {
    font-size: 4.266666667vw;
    font-weight: 400;
    color: #0f3c50;
    margin-top: 0;
    margin-right: 13.86666667vw;
    margin-bottom: 5.866666667vw;
  }

  .block-quote {
    border-left: 0.5333333333vw solid #0f3c50;
    margin: 4.266666667vw 0;
    margin-left: 11vw;
    padding: 0 4.266666667vw;
    font-weight: 400;
    font-size: 4.266666667vw;
    color: #0f3c50;
  }

  .practices_plan_card {
    display: none;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .practices-container {
    flex: 1;
    justify-content: center;
    color: black;
    background-color: white;
    padding: 7.25vw 0;
    font-size: 2.1875vw;
    font-weight: 600;
    max-width: 82.125vw;
  }

  .practices-h1 {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 1;
    /* text-shadow: 0.125vw 0.125vw 0.25vw rgba(0, 0, 0, 0.4); */
    color: #0f3c50;
    margin-bottom: 3vw;
  }

  .p-tag {
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 2.125vw;
    color: #0f3c50;
    width: 78%;
  }

  .expandable {
    display: none;
  }

  .practices_plan_card {
    border-radius: 2vw;
    box-shadow: 0px 0.25vw 1.25vw rgba(19, 61, 80, 0.1);
    position: relative;
  }

  .practiceTextTitle {
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 2.25vw;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .main-section {
    margin-bottom: 3.125vw;
  }

  .logo {
    display: block;
    max-width: 3.4375vw;
    height: auto;
  }

  .expanded-logo {
    justify-content: center;
    max-width: 3.4375vw;
    max-height: 3.4375vw;
    margin-left: auto;
    transition: opacity 0.5s ease-in-out;
  }

  .main-section {
    margin-bottom: 1.875vw;
  }

  .practices-container {
    max-width: 82.125vw;
    padding-left: 1.25vw;
    padding-right: 1.25vw;
    margin-left: auto;
    margin-right: auto;
  }

  .practiceTextDescription {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    margin-top: 0.625vw;
    margin-right: 0.0625vw;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .practices-container {
    flex: 1;
    justify-content: center;
    color: black;
    background-color: white;
    padding: 116px 0;
    font-size: 35px;
    font-weight: 600;
    max-width: 1314px;
  }

  .practices-h1 {
    font-size: 54px;
    font-weight: 700;
    line-height: 1;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); */
    color: #0f3c50;
    margin-bottom: 48px;
  }

  .p-tag {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    color: #0f3c50;
    width: 78%;
  }

  .expandable {
    display: none;
  }

  .practices_plan_card {
    border-radius: 32px;
    box-shadow: 0px 4px 20px rgba(19, 61, 80, 0.1);
    position: relative;
  }

  .practiceTextTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .main-section {
    margin-bottom: 50px;
  }

  .logo {
    display: block;
    max-width: 55px;
    height: auto;
  }

  .expanded-logo {
    justify-content: center;
    max-width: 55px;
    max-height: 55px;
    margin-left: auto;
    transition: opacity 0.5s ease-in-out;
  }

  .main-section {
    margin-bottom: 30px;
  }

  .practices-container {
    max-width: 1314px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .practiceTextDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
    margin-right: 1px;
  }
}
