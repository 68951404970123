.container {
  display: flex;
  flex: 1;
  justify-content: center;
  color: white;
  background-color: #0f3c50;
  padding: 8vw 10.66666667vw;
}

.container p {
  margin-top: 0;
  margin-bottom: 0;
}

.footer-h3 {
  font-size: 4.8vw;
  margin-bottom: 2.133333333vw;
  margin-top: 0;
}

.footer-p {
  font-size: 3.733333333vw;
  margin-bottom: 2.133333333vw;
}

.footer-ul {
  font-size: 3.733333333vw;
  margin-bottom: 2.133333333vw;
}

.bold-text {
  font-size: 4.8vw;
  font-weight: 600;
}

.normal-text {
  font-weight: 400;
  font-size: 3.733333333vw;
}

.tag-line-text {
  opacity: 0.6;
}

.social-media {
  display: flex;
  gap: 10px;
}

.outline-button {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding: 2.133333333vw 4.266666667vw;
  border-radius: 6.666666667vw;
  font-size: 3.733333333vw;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.semi-bold-text {
  font-weight: 500;
  font-size: 3.733333333vw;
}

.footer-row {
  /* max-width: 1314px; */
  margin-left: auto;
  margin-right: auto;
}

.footerLogoImage {
  width: 100%;
  /* height: 100%; */
  object-fit: fill;
}

.footer-col.logo {
  max-width: 64vw;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

.social-media {
  margin-top: 3.733333333vw;
  margin-bottom: 5.866666667vw;
  width: 6.4vw;
  height: 6.4vw;
}

.footer-h3 {
  font-weight: 600;
  font-size: 4.8vw;
  line-height: 6.4vw;
  margin-bottom: 0;
  margin-top: 0;
}

.normal-text {
  font-weight: 400;
  font-size: 3.733333333vw;
  line-height: 6.4vw;
  margin-bottom: 3.733333333vw;
}

.footerLogoImage {
  margin-bottom: 1.333333333vw;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .footer-row {
    flex-direction: column;
  }

  .footer-col {
    width: 100%;
    margin-bottom: 6.933333333vw;
  }

  /* .section-2 {
    display: none;
  } */

  .footer-col.logo {
    width: 60%;
  }

  .company-address {
    display: none;
  }

  .address-mobile {
    display: block;
  }

  .company-address-p {
    font-size: 3.733333333vw;
    margin-bottom: 2.133333333vw;
    font-weight: 500;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .footer-row {
    max-width: 82.125vw;
    display: flex;
    padding: 0 10%;
    justify-content: center;
  }

  .footer-col {
    width: 100%;
  }

  .company-address {
    display: block;
    color: white;
  }

  .company-address-p {
    font-size: 0.875vw;
    margin-bottom: 0.5vw;
    font-weight: 500;
  }

  .address-mobile {
    display: none;
  }

  .outline-button {
    border: 1px solid white;
    background-color: transparent;
    color: white;
    padding: 0.5vw 1vw;
    border-radius: 1.5625vw;
    font-size: 0.875vw;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .social-media {
    margin-top: 0.875vw;
    margin-bottom: 1.375vw;
    width: 1.5vw;
    height: 1.5vw;
  }

  .footer-h3 {
    font-weight: 600;
    font-size: 1.125vw;
    line-height: 1.5vw;
    margin-bottom: 0;
    margin-top: 0;
  }

  .normal-text {
    font-weight: 400;
    font-size: 0.875vw;
    line-height: 1.5vw;
    margin-bottom: 1.5vw;
  }

  .footerLogoImage {
    margin-bottom: 1.125vw;
  }

  .container {
    padding: 4.375vw 1.25vw;
  }

  .semi-bold-text {
    font-weight: 500;
    font-size: 0.875vw;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .footer-row {
    max-width: 1314px;
    display: flex;
    padding: 0 10%;
    justify-content: center;
  }

  .footer-col {
    width: 100%;
  }

  .company-address {
    display: block;
    color: white;
  }

  .company-address-p {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .address-mobile {
    display: none;
  }

  .outline-button {
    border: 1px solid white;
    background-color: transparent;
    color: white;
    padding: 8px 16px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .social-media {
    margin-top: 14px;
    margin-bottom: 22px;
    width: 24px;
    height: 24px;
  }

  .footer-h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .normal-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .footerLogoImage {
    margin-bottom: 18px;
  }

  .container {
    padding: 70px 20px;
  }

  .semi-bold-text {
    font-weight: 500;
    font-size: 14px;
  }
}
