body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#proposal .swiper-pagination-bullet {
  background: #ebeb78 !important;
  opacity: 1 !important;
  width: 3.2vw;
  height: 3.2vw;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 2.133333333vw);
}

#proposal .swiper-pagination-bullet-active {
  background: #0f3c50 !important;
}

#client-voices .swiper-pagination-bullet,
#our-team .swiper-pagination-bullet {
  background: #f0f9f4 !important;
  opacity: 1 !important;
  width: 3.2vw;
  height: 3.2vw;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 2.133333333vw);
}

#client-voices .swiper-pagination-bullet-active,
#our-team .swiper-pagination-bullet-active {
  background: #0f3c50 !important;
}

#our-clients .button-slide-nav, #team .button-slide-nav {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(60, 82, 157, 0.05);
  border-radius: 2.133333333vw;
  overflow: hidden;
}

#our-clients .prev-slide, #team .prev-slide {
  padding: 2.666666667vw;
  border-radius: 0;
  padding-right: 0;
  min-width: 14.93333333vw;
}

#our-clients .next-slide, #team .next-slide {
  padding: 2.666666667vw;
  border-radius: 0;
  padding-left: 0;
  min-width: 14.93333333vw;
}

#our-clients .prev-slide.swiper-button-disabled, #team .prev-slide.swiper-button-disable
#our-clients .next-slide.swiper-button-disabled, #team .next-slide.swiper-button-disabled {
  opacity: 0.5;
}

@media screen and (min-width: 600px) and (max-width: 1600px) {
  #proposal .swiper-pagination-bullet {
    background: #ebeb78 !important;
    opacity: 1 !important;
    width: 0.75vw;
    height: 0.75vw;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 0.5vw);
  }

  #proposal .swiper-pagination-bullet-active {
    background: #0f3c50 !important;
  }

  #client-voices .swiper-pagination-bullet,
  #our-team .swiper-pagination-bullet {
    background: #f0f9f4 !important;
    opacity: 1 !important;
    width: 0.75vw;
    height: 0.75vw;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 0.5vw);
  }

  #client-voices .swiper-pagination-bullet-active,
  #our-team .swiper-pagination-bullet-active {
    background: #0f3c50 !important;
  }

  #our-clients .button-slide-nav, #team .button-slide-nav {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(60, 82, 157, 0.05);
    border-radius: 0.5vw;
    overflow: hidden;
  }

  #our-clients .prev-slide, #team .prev-slide {
    padding: 0.625vw;
    border-radius: 0;
    padding-right: 0;
    min-width: 3.5vw;
  }

  #our-clients .next-slide, #team .next-slide {
    padding: 0.625vw;
    border-radius: 0;
    padding-left: 0;
    min-width: 3.5vw;
  }

  #our-clients .prev-slide.swiper-button-disabled, #team .prev-slide.swiper-button-disable
  #our-clients .next-slide.swiper-button-disabled, #team .next-slide.swiper-button-disabled {
    opacity: 0.5;
  }

  #our-clients .button-slide-nav {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #team .button-slide-nav {
    box-shadow: none;
  }
}

@media screen and (min-width: 1600px) {
  #our-clients .button-slide-nav {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #team .button-slide-nav {
    box-shadow: none;
  }

  #proposal .swiper-pagination-bullet {
    background: #ebeb78 !important;
    opacity: 1 !important;
    width: 12px;
    height: 12px;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
  }

  #proposal .swiper-pagination-bullet-active {
    background: #0f3c50 !important;
  }

  #client-voices .swiper-pagination-bullet,
  #our-team .swiper-pagination-bullet {
    background: #f0f9f4 !important;
    opacity: 1 !important;
    width: 12px;
    height: 12px;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
  }

  #client-voices .swiper-pagination-bullet-active,
  #our-team .swiper-pagination-bullet-active {
    background: #0f3c50 !important;
  }

  #our-clients .button-slide-nav, #team .button-slide-nav {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(60, 82, 157, 0.05);
    border-radius: 8px;
    overflow: hidden;
  }

  #our-clients .prev-slide, #team .prev-slide {
    padding: 10px;
    border-radius: 0;
    padding-right: 0;
    min-width: 56px;
  }

  #our-clients .next-slide, #team .next-slide {
    padding: 10px;
    border-radius: 0;
    padding-left: 0;
    min-width: 56px;
  }

  #our-clients .prev-slide.swiper-button-disabled, #team .prev-slide.swiper-button-disable
  #our-clients .next-slide.swiper-button-disabled, #team .next-slide.swiper-button-disabled {
    opacity: 0.5;
  }
}
