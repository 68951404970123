.proposal {
  background-color: #fff;
}

.container {
  padding-left: 8vw;
  padding-right: 8vw;
  margin-left: auto;
  margin-right: auto;
}

.proposal__heading {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 10.66666667vw;
}

.title {
  font-size: 5.333333333vw;
  font-weight: 700;
  line-height: 9.066666667vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 5.333333333vw;
}

.description {
  font-size: 4.266666667vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #0f3c50;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .container {
    max-width: 82.125vw;
  }

  .proposal__heading {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 3.75vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 4.6875vw;
  }

  .title {
    font-size: 1.5vw;
    font-weight: 700;
    line-height: 2.125vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 1.25vw;
  }

  .description {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.5vw;
    color: #0f3c50;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1314px;
  }

  .proposal__heading {
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 75px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #0f3c50;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }
}
