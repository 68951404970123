.approach p {
  white-space: pre-line;
}

.approach {
  position: relative;
  display: block;
  flex-direction: column;
  height: fit-content;
  padding: 15% 0 5% 0;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ebeb78;
}

.approchesTopBgSp {
  display: block;
  margin-bottom: -0.2666666667vw;
}

.approchesTopBg {
  display: none;
  /* margin-bottom: -1.866666667vw; */
}

.approach__title {
  font-size: 8.533333333vw;
  font-weight: 700;
  color: #0f3c50;
  padding-top: 0;
  padding-bottom: 9.8vw;
  padding-left: 10vw;
  padding-right: 8vw;
}

.approach__container {
  margin: 0;
  display: flex;
  flex: 0 0 20%;
  width: 100%;
  gap: 1%;
  flex-wrap: wrap;
}

.approach__titleWithBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8.266666667vw;
  width: fit-content;
  padding: 0px 2.133333333vw;
  height: fit-content;
  color: white !important;
  font-size: 4.266666667vw;
}

.approach__firstApproach {
  display: flex;
  height: fit-content;
  width: 171.2vw;
  flex-direction: column;
  background-color: white;
  border-radius: 8.533333333vw;
  padding: 12.066667vw 8vw 0px 8vw;
}

.approach__category {
  display: flex;
  flex-direction: row;
}

/* .approach__category p {
  margin: 0;
} */

.approach__categoryName {
  min-width: 28.26666667vw;
  max-width: 28.26666667vw;
  font-size: 4.266666667vw;
  line-height: 6.4vw;
  font-weight: 700;
  /* margin-top: 0; */
}

.approach__categoryContent {
  width: fit-content;
  font-size: 4.266666667vw;
  line-height: 6.4vw;
  /* margin-top: 0; */
}

.approach__secondApproach {
  display: flex;
  height: fit-content;
  width: 171.2vw;
  flex-direction: column;
  background-color: white;
  border-radius: 8.533333333vw;
  padding: 12.066667vw 8vw 0px 8vw;
}

.approach__firstApproachMobile {
  display: flex;
  font-size: 4.266666667vw;
  line-height: 6.4vw;
}

.approach__mobileBackgroundContainer {
  background: #ebeb78;
  height: fit-content;
  padding-top: 2.666666667vw;
  display: inline-flex;
}

.approach__mobileBackground {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
}

.approach__firstApproachTitle {
  color: #0f3c50;
  font-weight: 700;
  font-size: 5.333333333vw;
  line-height: 10.66666667vw;
  margin-top: 0;
  margin-bottom: 6.4vw;
}

.approach__secondApproachTitle {
  font-size: 5.333333333vw;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.333333333vw;
}

.approach__thirdApproachContent {
  font-size: 4.266666667vw;
  font-weight: 700;
}

/* .collapseFirstContent {
  display: none;
} */

.collapseFirstContentMobile {
  align-self: center;
  display: block;
}

.approach__firstApproachContent {
  display: none;
  font-size: 4.266666667vw;
  line-height: 6.4vw;
}

.approach__secondApproachContent {
  display: none;
  font-size: 4.266666667vw;
  line-height: 6.4vw;
}

.approach__secondApproachContentMobile {
  font-size: 4.266666667vw;
  line-height: 6.4vw;
}

.bgRb,
.bgRt,
.bgLb {
  display: none;
}

@media only screen and (max-width: 600px) {
}

/* Media Query for Tablets Ipads portrait mode */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .approchesTopBg {
    display: block;
    margin-bottom: -0.08vw;
  }

  .approchesTopBgSp {
    display: none;
  }

  .collapseFirstContent {
    display: block;
  }
  .approach__firstApproachMobile {
    display: none;
  }

  .approach__mobileBackgroundContainer {
    background: #ebeb78;
    display: none;
    height: fit-content;
    padding-top: 0.625vw;
  }

  .collapseFirstContentMobile {
    display: none;
  }

  .approach__secondApproachContentMobile {
    display: none;
    font-size: 1.25vw;
    line-height: 2.5vw;
  }

  .approach {
    height: fit-content;
    padding: 0;
    justify-content: center;
    background-size: cover;
    background-position: right;
    display: flex;
    flex: 1;
    align-items: center;
  }

  .approach__title {
    padding-top: 3.625vw;
    padding-left: 2.625vw;
    padding-right: 1.875vw;
    font-size: 3.375vw;
    padding-bottom: 0;
  }

  .approach__container {
    display: inline-flex;
    width: 100%;
    max-width: 85.875vw;
    margin-left: auto;
    margin-right: auto;
    gap: 1.25vw;
    padding-bottom: 5%;
  }

  .approach__firstApproach {
    display: flex;
    height: fit-content;
    min-height: 19.1875vw;
    max-width: 40.125vw;
    flex-direction: column;
    background-color: white;
    border-radius: 2vw;
    padding: 2.625vw 1.5vw;
  }

  .approach__secondApproach {
    display: flex;
    min-height: 19.1875vw;
    height: fit-content;
    max-width: 40.125vw;
    flex-direction: row;
    background-color: white;
    border-radius: 2vw;
    padding: 2.5vw 1.5vw 2.5vw 1.5vw;
  }

  .approach__mobileBackgroundContainer {
    display: none;
  }

  .approach__firstApproachTitle {
    font-size: 1.5vw;
    line-height: 2.5vw;
    margin-bottom: 1.625vw !important;
  }

  .approach__secondApproachTitle {
    font-size: 1.25vw;
    line-height: 1.875vw;
    margin-bottom: 0.75vw !important;
  }

  .approach__firstApproachContent {
    display: block;
    margin-top: 2vw;
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .approach__secondApproachContent {
    display: block;
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .approach__categoryName {
    min-width: 6.625vw;
    max-width: 6.625vw;
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .approach__categoryContent {
    font-size: 1vw;
    line-height: 1.5vw;
  }

  .approach__categoryName,
  .approach__categoryContent {
    margin-top: 0.875vw;
    margin-bottom: 0.875vw;
  }

  .approach__thirdApproachContent {
    font-size: 1vw;
    margin-top: 1.875vw;
  }

  .approach__titleWithBox {
    font-size: 1vw;
    min-height: 2vw;
    padding: 0px 0.5vw;
  }

  .bgRb {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 67.75vw;
    height: 33.6875vw;
    z-index: 1;
  }

  .bgRt {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 32.8125vw;
    height: 5.125vw;
  }

  .bgLb {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16.75vw;
    height: 12.9375vw;
  }
}

@media screen and (min-width: 1600px) {
  .approchesTopBg {
    display: block;
    margin-bottom: -1px;
  }

  .approchesTopBgSp {
    display: none;
  }

  .approach__firstApproachMobile {
    display: none;
  }

  .approach__mobileBackgroundContainer {
    background: #ebeb78;
    display: none;
    height: fit-content;
    padding-top: 10px;
  }

  .collapseFirstContentMobile {
    display: none;
  }

  .approach__secondApproachContentMobile {
    display: none;
    font-size: 20px;
    line-height: 40px;
  }

  .approach {
    height: fit-content;
    padding: 0;
    justify-content: center;
    background-size: cover;
    background-position: right;
    display: flex;
    flex: 1;
    align-items: center;
  }

  .approach__title {
    padding-top: 58px;
    padding-left: 42px;
    padding-right: 30px;
    font-size: 54px;
    padding-bottom: 0;
  }

  .approach__container {
    display: inline-flex;
    width: 100%;
    max-width: 1374px;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    padding-bottom: 5%;
  }

  .approach__firstApproach {
    display: flex;
    height: fit-content;
    min-height: 307px;
    max-width: 642px;
    flex-direction: column;
    background-color: white;
    border-radius: 32px;
    padding: 42px 24px;
  }

  .approach__secondApproach {
    display: flex;
    min-height: 307px;
    height: fit-content;
    max-width: 642px;
    flex-direction: row;
    background-color: white;
    border-radius: 32px;
    padding: 40px 24px 40px 24px;
  }

  .approach__mobileBackgroundContainer {
    display: none;
  }

  .approach__firstApproachTitle {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 26px !important;
  }

  .approach__secondApproachTitle {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px !important;
  }

  .approach__firstApproachContent {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .approach__secondApproachContent {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .approach__categoryName {
    min-width: 106px;
    max-width: 106px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .approach__categoryContent {
    font-size: 16px;
    line-height: 24px;
  }

  .approach__categoryName,
  .approach__categoryContent {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .approach__thirdApproachContent {
    font-size: 16px;
    margin-top: 30px;
  }

  .approach__titleWithBox {
    font-size: 16px;
    min-height: 32px;
    padding: 0px 8px;
  }

  .bgRb {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1084px;
    height: 539px;
  }

  .bgRt {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 525px;
    height: 82px;
  }

  .bgLb {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 268px;
    height: 207px;
  }
}
