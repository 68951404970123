.contactUs {
  display: flex;
  min-height: 100%;
  padding: 69px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../Assets/Images/contact-us-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.contactUs__titleBox {
  position: relative;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 79px;
  width: fit-content;
  padding: 0 20px;
  background-color: #ebeb78;
}

.contactUs__titleBoxMobile {
  display: none;
}

.contactUs__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0F3C50;
}

.contactUs__container {
  padding: 69px;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 20%;
  justify-content: space-between;
  flex-direction: row;
  background: white;
  width: fit-content;
  height: fit-content;
  gap: 72px;
  border-radius: 32px;
}

.contactUs__inputTitle {
  font-size: 14px;
  font-weight: 400;
  color: #0F3C50;
}

.contactUs__inputField {
  max-width: 417px;
  width: 417px;
  height: 59px;
  border: 1px solid #0f3c50;
  border-radius: 16px;
  padding: 20px 12px 20px 12px;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.contactUs__messageInputField {
  max-width: 417px;
  width: 417px;
  height: 309px;
  border: 1px solid #0f3c50;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 12px 10px 12px;
  box-sizing: border-box;
  overflow: auto;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.contactUs__inputField::placeholder,
.contactUs__messageInputField::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.contactUs__checkBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding-top: 10px;
}

.contactUs__checkBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #0F3C50;
}

.contactUs__checkMark {
  display: flex;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  justify-content: center;
  align-items: center;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  vertical-align: middle;
  font-weight: 700;
  border: 2px solid #0f3c50;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.contactUs__checkMark:hover,
.contactUs__checkMarkError:hover {
  background-color: #ccc;
}

.contactUs__checkMark:checked,
.contactUs__checkMarkError:checked {
  background-size: cover;
  background-position: center;
  background-image: url("../../Assets/svg/check-icon.svg");
  border: 0;
}

.contactUs__agreementContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  height: fit-content;
  max-width: 824px;
}

.contactUs__checkBoxContainerMobile {
  display: none;
}

.contactUs__agreement {
  font-size: 11px;
  display: flex;
  max-width: 410px;
  gap: 8px;
  width: 410px;
  height: fit-content;
  justify-content: space-between;
  flex-direction: row;
}

.contactUs__agreementText {
  width: 80vw;
}

.contactUs__submitButtonContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.contactUs__checkBoxWarning {
  display: flex;
}

.contactUs__submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 191px;
  max-width: 191px;
  height: 34px;
  background: #ebeb78;
  border-radius: 16px;
  margin-top: 36px;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  color: #0F3C50;
  font-family: poppins;
}

.contactUs__checkMarkError {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #fdae62;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.contactUs__errorText {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    color: #fdae62;
    padding: 0;
    margin: 0;
}

.contactUs__errorTextMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .contactUs {
    padding: 69px 0 0 0;
  }

  .contactUs__container {
    overflow-x: hidden;
    max-width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 7% 2%;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .contactUs__leftPart,
  .contactUs__rightPart {
    width: 80vw;
    max-width: 80vw;
  }

  .contactUs__inputField {
    width: 90%;
    max-width: 90%;
    height: 8vw;
    border: 1px solid #0f3c50;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .contactUs__titleBox {
    display: none;
  }

  .contactUs__checkBoxWarning {
    display: none;
  }

  .contactUs__titleBoxMobile {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 28px 0 38px 7%;
  }

  .contactUs__title {
    background: #ebeb78;
    padding: 0;
    margin: 0;
  }

  .contactUs__checkBoxContainer {
    display: none;
  }

  .contactUs__errorText {
    display: none;
  }

  .contactUs__errorTextMobile {
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fdae62;
  }
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
  .contactUs__container {
    overflow-x: hidden;
    max-width: 90vw;
    padding: 7% 2%;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .contactUs__leftPart,
  .contactUs__rightPart {
    width: 80vw;
    max-width: 80vw;
  }

  .contactUs__checkBoxWarning {
    display: none;
  }

  .contactUs__checkBoxContainer {
    display: none;
  }

  .contactUs__errorText {
    display: none;
  }

  .contactUs__errorTextMobile {
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fdae62;
  }
}

@media screen and (min-width: 200px) and (max-width: 1280px) {
  .contactUs__checkBoxContainerMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    max-width: 80vw;
    padding: 0;
    margin: 0;
    gap: 12px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .contactUs__agreement {
    width: 80vw;
    max-width: 80vw;
  }

  .contactUs__checkBox {
    display: flex;
    min-width: 70px;
    width: fit-content;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
  }

  .contactUs__inputField {
    width: 100%;
    height: 60px;
    max-width: 100%;
    border: 1px solid #0f3c50;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .contactUs__messageInputField {
    width: 100%;
    max-width: 100%;
    border: 1px solid #0f3c50;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .contactUs__titleBox {
    margin-bottom: 80px;
  }

  .contactUs__submitButtonContainer {
    width: 100%;
    justify-content: flex-end;
  }

  .contactUs__submitButton {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 100%;
  }

  .contactUs__errorText {
    display: none;
  }

  .contactUs__errorTextMobile {
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fdae62;
  }
}
