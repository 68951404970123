.contactUsHomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ebeb78;
  position: relative;
  padding-left: 6.4vw;
  padding-right: 6.4vw;
  height: 117.0666667vw;
  margin-top: 70px;
}

.contactUsHomeSection__titleContainer {
  padding-top: 4.266666667vw;
  display: flex;
  flex-direction: column;
}

.contactUsHomeSection__formContainer p {
  font-size: 4.266666667vw;
}

.contactUsHomeSection__contactUsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4.266666667vw;
  width: 100%;
}

.contactUsHomeSection__title {
  color: #0f3c50;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 8.533333333vw;
  line-height: 10.66666667vw;
  margin-top: 37.33333333vw;
}

.contactUsHomeSection__description {
  color: #0f3c50;
  font-size: 4.266666667vw;
  font-weight: 500;
  max-width: 151.7333333vw;
}

.contactUsHomeSection__formContainer {
  display: flex;
  align-items: center;
  background: #0f3c50;
  width: 112.2666667vw;
  height: 12.8vw;
  padding: 0 4.8vw;
  color: white;
  border-radius: 18.66666667vw;
}

.contactUsHomeSection__iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #0f3c50;
  width: 12.8vw;
  height: 12.8vw;
  border-radius: 50%;
  cursor: pointer;
}

.contactUsHomeSection__input {
  display: none;
}

.contactUsHomeSection__icon {
  width: 6.4vw;
  height: 3.733333333vw;
}

.bgT {
  display: block;
  position: absolute;
  right: 0;
  top: -13.33333333vw;
  width: 33.06666667vw;
  height: 61.86666667vw;
}

.bgB {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98.66666667vw;
  height: 85.06666667vw;
}

.bgL,
.bgR {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1600px) {
  .contactUsHomeSection__title {
    font-size: 3.25vw;
    line-height: 2.5vw;
    margin-top: 4.125vw;
  }

  .contactUsHomeSection__description {
    max-width: 35.5625vw;
    font-size: 1.5vw;
  }

  .contactUsHomeSection {
    height: 22.9375vw;
    margin-top: 16.9375vw;
    padding-left: 0;
    padding-right: 0;
  }

  .contactUsHomeSection__formContainer {
    width: 26.3125vw;
    height: 3vw;
    padding: 0 1.375vw;
    border-radius: 4.375vw;
  }

  .contactUsHomeSection__formContainer p {
    font-size: 1vw;
  }

  .contactUsHomeSection__iconContainer {
    width: 3vw;
    height: 3vw;
  }

  .contactUsHomeSection__icon {
    width: 1.5vw;
    height: 0.875vw;
  }

  .contactUsHomeSection__titleContainer {
    padding-top: 1vw;
  }

  .contactUsHomeSection__contactUsContainer {
    gap: 1vw;
  }

  .bgR {
    display: block;
    position: absolute;
    right: 0;
    top: -8.1875vw;
    width: 49.3125vw;
    height: 31.10875vw;
  }

  .bgL {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 29.469375vw;
    height: 22.754375vw;
  }

  .bgT,
  .bgB {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .contactUsHomeSection__title {
    font-size: 52px;
    line-height: 40px;
    margin-top: 66px;
  }

  .contactUsHomeSection__description {
    max-width: 569px;
    font-size: 24px;
  }

  .contactUsHomeSection {
    height: 367px;
    margin-top: 271px;
    padding-left: 0;
    padding-right: 0;
  }

  .contactUsHomeSection__formContainer {
    width: 375px;
    height: 48px;
    padding: 0 22px;
    border-radius: 70px;
  }

  .contactUsHomeSection__formContainer p {
    font-size: 16px;
  }

  .contactUsHomeSection__iconContainer {
    width: 48px;
    height: 48px;
  }

  .contactUsHomeSection__icon {
    width: 24px;
    height: 14px;
  }

  .contactUsHomeSection__titleContainer {
    padding-top: 16px;
  }

  .contactUsHomeSection__contactUsContainer {
    gap: 16px;
  }

  .bgR {
    display: block;
    position: absolute;
    right: 0;
    top: -131px;
    width: 789px;
    height: 497.74px;
  }

  .bgL {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 471.51px;
    height: 364.07px;
  }

  .bgT,
  .bgB {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  /* .contactUsHomeSection {
    background-size: cover;
    background-position: right top;
    padding: 0 20px;
    min-height: 510px;
  }

  .contactUsHomeSection__titleContainer {
    margin-top: 130px;
  } */

  .contactUsHomeSection {
    /* background-size: cover; */
    /* background-position: right;
    background-repeat: no-repeat;
    min-height: 600px;
    background-image: url("../../Assets/Images/contact-us-section-background.png"); */
  }
}
