.aboutUsOurTeamMainContainer {
  padding: 8vw;
  background-color: #c3dcaf;
}

.aboutUsOurTeamContainer {
  max-width: 350.4vw;
  height: 106.6666667vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8.533333333vw;
  overflow: hidden;
  background-color: #0f3c50;
}

.aboutUsOurTeamHeader {
  margin-bottom: 10.66666667vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.aboutUsOurTeamHeaderTitle {
  font-size: 14.4vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #0f3c50;
  margin-bottom: 0;
  margin-top: 0;
}

.aboutUsOurTeamMedia {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}

.aboutUsOurTeamImage {
  width: 100%;
  object-fit: cover;
}

.aboutUsOurTeamTextTitle {
  font-size: 6.4vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #fff;
}

.aboutUsOurTeamTextDescription {
  font-size: 3.733333333vw;
  font-weight: 500;
  line-height: 5.6vw;
  color: #fff;
}

.sp {
  display: inline-block;
}

.pc {
  display: none;
  border-radius: 2.133333333vw;
}

.aboutUsOurTeamDescTextContainer::-webkit-scrollbar {
  display: none;
}

.aboutUsOurTeamContainerMobile {
  display: none;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
  .aboutUsOurTeamHeaderTitle {
    font-size: 8.533333333vw;
    font-weight: 700;
    line-height: 10.66666667vw;
  }

  .aboutUsOurTeamContainer {
    display: none;
  }

  .aboutUsOurTeamContainerMobile {
    height: 139.4666667vw;
    padding: 15.46666667vw 11.46666667vw 0px 11.46666667vw;
    margin-bottom: 10.66666667vw;
    background-position: 40% 60%;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    display: flex;
    box-shadow: 0px 1.066666667vw 5.333333333vw rgba(19, 61, 80, 0.1);
    border-radius: 8.533333333vw;
    overflow: hidden;
  }

  .aboutUsOurTeamDescTextContainer {
    max-height: 106.6666667vw;
  }

  .aboutUsOurTeamImage {
    width: 100%;
    object-fit: cover;
    object-position: 10% 50%;
  }

  .aboutUsOurTeamTextDescription ul {
    padding-left: 3.5vw;
  }
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .aboutUsOurTeamMainContainer {
    padding-top: 8.75vw;
    padding-bottom: 8.75vw;
    padding-left: 0;
    padding-right: 0;
  }

  .aboutUsOurTeamHeaderTitle {
    font-size: 3.375vw;
    line-height: 2.5vw;
    margin-bottom: 0;
    margin-top: 0;
  }

  .aboutUsOurTeamContainer {
    display: flex;
    flex-direction: row;
    gap: 4vw;
    border-radius: 1vw;
    max-width: 82.125vw;
    height: 25vw;
  }

  .aboutUsOurTeamHeader {
    margin-bottom: 2.5vw;
  }

  .aboutUsOurTeamMedia {
    display: block;
    width: 50%;
    margin: 0;
  }

  .aboutUsOurTeamImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .aboutUsOurTeamText {
    width: 34.6875vw;
    padding: 2vw 2vw 2vw 0;
  }

  .aboutUsOurTeamTextTitle {
    font-size: 2vw;
    font-weight: 700;
    line-height: 2.5vw;
    color: #fff;
    margin-bottom: 1vw;
  }

  .aboutUsOurTeamTextDescription {
    font-size: 1vw;
    font-weight: 500;
    line-height: 1.75vw;
    color: #fff;
  }

  .aboutUsOurTeamTextDescription ul {
    padding-left: 1.5vw;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .aboutUsOurTeamMainContainer {
    padding-top: 140px;
    padding-bottom: 140px;
    padding-left: 0;
    padding-right: 0;
  }

  .aboutUsOurTeamHeaderTitle {
    font-size: 54px;
    line-height: 40px;
  }

  .aboutUsOurTeamContainer {
    display: flex;
    flex-direction: row;
    gap: 64px;
    border-radius: 16px;
    max-width: 1314px;
    height: 400px;
  }

  .aboutUsOurTeamHeader {
    margin-bottom: 40px;
  }

  .aboutUsOurTeamMedia {
    display: block;
    width: 50%;
    margin: 0;
  }

  .aboutUsOurTeamImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .aboutUsOurTeamText {
    width: 555px;
    padding: 32px 32px 32px 0;
  }

  .aboutUsOurTeamTextTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    margin-bottom: 16px;
  }

  .aboutUsOurTeamTextDescription {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #fff;
  }

  .aboutUsOurTeamTextDescription ul {
    padding-left: 24px;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }
}
