.clientVoices {
  background-color: #fff;
  margin-top: 10.66666667vw;
  margin-bottom: 10.66666667vw;
}

.container {
  padding-left: 7.466666667vw;
  padding-right: 7.466666667vw;
  margin-left: auto;
  margin-right: auto;
}

.clientVoices__heading {
  font-size: 8.533333333vw;
  font-weight: 700;
  line-height: 10.66666667vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 10.66666667vw;
}

.personCard {
  border-radius: 8.533333333vw;
  background: rgba(60, 82, 157, 0.05);
  padding-left: 9.2vw;
  padding-right: 9.2vw;
  padding-bottom: 11.2vw;
  padding-top: 20.26666667vw;
  position: relative;
  margin-top: 20.26666667vw;
  margin-bottom: 8.533333333vw;
}

.personCard__profileContainer {
  width: 31.46666667vw;
  height: 31.46666667vw;
  object-fit: cover;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: -20.26666667vw;
  left: 50%;
  transform: translateX(-50%);
  border-color: #0f3c50;
  border-width: 6px;
  border-style: solid;
}

.personCard__profile {
  width: 100%;
}

.personCard__name {
  text-align: center;
  font-size: 5.333333333vw;
  font-weight: 700;
  line-height: 8.533333333vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 1.066666667vw;
}

.personCard__position {
  text-align: center;
  font-size: 4.266666667vw;
  font-weight: 600;
  line-height: 6.4vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 4.266666667vw;
}

.personCard__description {
  text-align: center;
  font-size: 3.733333333vw;
  font-weight: 400;
  font-style: italic;
  line-height: 5.6vw;
  color: #0f3c50;
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;
}

.sp {
  display: block;
}

.pc {
  display: none;
}

/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets) */
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .clientVoices {
    background-color: #fff;
    margin-top: 8.75vw;
    margin-bottom: 8.75vw;
  }

  .container {
    max-width: 82.125vw;
  }

  .clientVoices__heading {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 3.75vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 11.46666667vw;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .clientVoices__heading {
    font-size: 3.375vw;
    font-weight: 700;
    line-height: 2.5vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 3.75vw;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }

  .personCard {
    border-radius: 2vw;
    background: rgba(60, 82, 157, 0.05);
    box-shadow: 0px 1.066666667vw 1.066666667vw rgba(0, 0, 0, 0.25);
    padding-left: 2.625vw;
    padding-right: 2.625vw;
    padding-bottom: 2.625vw;
    padding-top: 8vw;
    position: relative;
    margin-top: 6.9375vw;
    margin-bottom: 2vw;
  }

  .personCard__profileContainer {
    width: 8.75vw;
    height: 8.75vw;
    object-fit: cover;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: -4.75vw;
    left: 50%;
    transform: translateX(-50%);
    border-color: #0f3c50;
    border-width: 0.375vw;
    border-style: solid;
  }

  .personCard__profile {
    width: 100%;
  }

  .personCard__name {
    text-align: center;
    font-size: 1.5vw;
    font-weight: 700;
    line-height: 2vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0.25vw;
  }

  .personCard__position {
    text-align: center;
    font-size: 1vw;
    font-weight: 600;
    line-height: 1.5vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 1vw;
  }

  .personCard__description {
    text-align: center;
    font-size: 1vw;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5vw;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktops */
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1314px;
  }

  .clientVoices {
    background-color: #fff;
    margin-top: 140px;
    margin-bottom: 140px;
  }

  .clientVoices__heading {
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 43px;
  }

  .sp {
    display: none;
  }

  .pc {
    display: block;
  }

  .clientVoices__heading {
    font-size: 54px;
    font-weight: 700;
    line-height: 40px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 60px;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }

  .personCard {
    border-radius: 32px;
    background: rgba(60, 82, 157, 0.05);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 42px;
    padding-right: 42px;
    padding-bottom: 42px;
    padding-top: 128px;
    position: relative;
    margin-top: 111px;
    margin-bottom: 32px;
  }

  .personCard__profileContainer {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: -76px;
    left: 50%;
    transform: translateX(-50%);
    border-color: #0f3c50;
    border-width: 6px;
    border-style: solid;
  }

  .personCard__profile {
    width: 100%;
  }

  .personCard__name {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 4px;
  }

  .personCard__position {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .personCard__description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    line-height: 24px;
    color: #0f3c50;
    margin-top: 0;
    margin-bottom: 0;
  }
}
